@use "../../" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.SecurityTesting {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    color: white;
    @include tablet-viewport {
        background-image: url("../../assets/ServiceBG.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    &::before {
        content: "";
        background: rgba(0, 0, 0, 0.8); 
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1; 
    }

    &__box {
        @include tablet-viewport{
            display: flex;
        flex-direction: row;
        margin-bottom: 2rem;
        }
    }
    &__box-image {
        display: none;
        @include tablet-viewport{
            display: flex;
        height: 100%;
        width: 35%;
        }
    }

    &__content {
        z-index: 1;
        display: flex;
        flex-direction: column;
        @include tablet-viewport {
            margin: 2rem;
        }
        @include desktop-viewport {
            margin: 4rem;
        }
    }

    &__Holder {
        display: flex;
        text-align: left;
        margin: 0rem 0rem 2rem 1rem;
        z-index: 1;
        gap: 2rem;
        flex-direction: column;
        @include tablet-viewport{
            display: flex;
            flex-direction: row;
        }
        &-title {
            font-size: 3rem;
            font-weight: 600;
            margin-top: 1rem;
            @include tablet-viewport {
                margin-top: 0rem;
            }
        }
    }

    &__description {
        margin-left: 1rem;
        z-index: 1;
        line-height: 1.5;
        font-size: 1.25rem;
        margin-bottom: 2rem;
        @include tablet-viewport {
            width: 90%;
        }
    }

    &__button {
        display: block;
        // width: 50%;
        text-align: center;
        padding: 1rem;
        margin: 1rem;
        background-color: #b91313;
        color: #fff;
        text-transform: capitalize;
        font-weight: bold;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        z-index: 3; 
        position: relative; 
        align-self: center;
        width: 90%;
        &:hover {
          background-color: darken(#b91313, 10%);
        }
        @include tablet-viewport {
          width: 40%;
        }
        @include desktop-viewport {
          width: 30%;
        }
      }

    &__offering {
        font-size: 1.25rem;
        font-weight: 600;
        margin-left: 1rem;
        z-index: 1;
        margin-bottom: 2rem;
        color: #FD3842;
    }

    &__offering-list {
        @extend .SecurityTesting__offering; 
        font-weight: 400;
        line-height: 1.5;
        color: #fff;
    }
    li {
        list-style: none;
    gap: 1rem;
    display: flex;
    align-items: center;
    margin-top: 1rem;
    }
    &__benefit {
        @extend .SecurityTesting__offering; 
        &__text {
            @extend .SecurityTesting__description;
            width: 90%;
        }
    }

    .SecurityTesting__offering-listIcon, .SecurityTesting__Holder-icon  {
        height: 2rem;
        z-index: 1;
      }
    
      a {
        text-decoration: none;
        color: #fff;
      }
}
