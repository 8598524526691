@use "../../" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.PreviewHeader {
  display: flex;
  flex-direction: column;
  // align-items: center;
  
  &__title {
    color: rgb(253, 56, 66);
    padding-top: 1rem;
     margin-left: 1rem;
     font-size: 2rem;
     margin-bottom: 2rem;
     @include tablet-viewport {
      margin-left: 3rem;
     }
     @include desktop-viewport {
      margin-left: 4rem;
     }
  }
  &__blurb {
    text-align: left;
    line-height: 1.7;
    margin: 0rem 1rem 1rem 0rem;
    color: #dddddd;
    font-size: 1.25rem;
    width: 90%;
    align-self: center;
    }
  }

.Preview {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.PreviewServices {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem;
  color: #05ccef;

  & :active {
    color: #05ccef;
  }
  @include tablet-viewport {
    flex-direction: row;
  }

  & img {
    height: 3rem;
  }
  a {
    text-decoration: none;
    color: #05ccef;
    font-weight: 600;
  }

  &__holder {
    padding: 1rem;
    margin: 1.25rem;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 0.2s ease; /* Add transition property */
  
    @include tablet-viewport {
      width: 40%;
      min-height: 25vw;
    }
  
    @include desktop-viewport {
      max-width: 30%;
    }
  }
  

  &__title {
    color: white;
    font-size: 1rem;
    font-weight: bold;
    text-align: left;
    margin: 0.625rem 0; 
    z-index: 2;
  }

  &__list {
    margin-top: 0.625rem;
    &-invisible{
      color: black;
    }
  }

  &__description {
    font-size: 12px;
    margin: 0.625rem 0;
    color: #dbdbdb;
  }

  &__subholder {
    display: flex;
    flex-direction: row;
    width: 80%;
    align-items: center;
    margin-left: 3rem;
  }

  &__text {
    @extend .PreviewHeader__blurb;
    
  }

  &__label {
    color: #dbdbdb;
    @extend .PreviewHeader__blurb;
    font-size: 3.5rem;
    @include tablet-viewport {
      font-size: 4rem;
    }
  }

  &__caption {
    @extend .PreviewHeader__blurb;
    color: rgb(253, 56, 66);
  }

  &__caption-button {
    display: block;
    text-align: center;
    padding: 1rem;
    margin: 1rem;
    background-color: #b91313;
    color: #fff;
    text-transform: capitalize;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    z-index: 3;
    position: relative;
    width: 50%;
    align-self: center;

    &:hover {
      background-color: darken(#b91313, 10%);
    }

    @include tablet-viewport {
      width: 30%;
    }

    & a {
      color: white;
      text-decoration: none;
    }
  }

  li {
    color: white;
    list-style: none;
    margin-bottom: 2rem;
    border-bottom: 1px solid white;
    padding-bottom: 0.5rem; 
    text-align: left;
    cursor: pointer;
    transition: transform 0.2s;
    &:hover {
      transform: scale(1.05); /* Enlarge on hover */
      color: #05ccef;
    }
  } 
}
