@use "../../" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

@media screen and (min-width: 768px) {
  .header__services-dropdown {
    padding: 15px 6.25rem;
    position: absolute;
    top: 70px;
    left: 0;
    padding-top: 30px;
    display: flex;
    // align-items: center;
    justify-content: flex-end;
    color: #BDC5D5;
    background-color: #121213;
    overflow: hidden;
    width: 100%;
    grid-gap: 30px;
    .header__desktop_title {
      color: #05ccef;
    }
    ul {
      line-height: 2;
    }
    a {
      color: #BDC5D5;
      text-decoration: none;
      &:hover {
        color: #05ccef;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .header__services-dropdown {
    overflow: hidden;
    transition: max-height 0.3s ease;
    padding: 10px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    position: relative;
    color: white;
    .header__desktop_title {
      text-align: center;
      color: #05ccef;
      margin-top: 1rem;
    }
    ul {
      li {
        text-align: center;
        margin-bottom: 2;
        line-height: 2;

        a {
          color: #BDC5D5;
          text-decoration: none;
          &:hover {
            color: #05ccef;
          }
        }
      }
    }
  }
}

.header__services-dropdown.active {
  max-height: 500px;
}