@use "../../" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.map__container {
    display: none;
    & h1, svg {
        display: none;
    }
    @include tablet-viewport {
position: relative;
overflow: hidden;
display: flex;
justify-content: center;
height: 20rem;
background-color: black;
    }
} 

.map iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    & h1, svg {
        display: none;
    }
}


