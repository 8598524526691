@use "../../" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.hero {
  width: 100%;
  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    background-image: url("../../assets/hero2.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 1rem;
    
    @include tablet-viewport {
      align-items: center;
      height: 100%;
    }
  }

  &__container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient( rgba(0, 0, 0, 0), #000 69%);
    z-index: 1;
  }

  &__icon {
    z-index: 2;
    display: flex;
    align-items: center;
    gap: .5rem;
    @include tablet-viewport {
      margin-top: 1rem;
    }

    & img {
      height: 3rem;
      @include tablet-viewport {
        height: 6rem;
        text-align: left;
      }
    }
  }

  &__slogan {
    font-size: 2rem;
    color: #ffffff;
    position: relative;
    z-index: 3;
    @include tablet-viewport {
      width: 100%;
      font-size: 4rem;
    }
  }

  &__description {
    color: white;
    margin: 2rem 1rem 0rem 7rem;
    position: relative;
    z-index: 2;
    transition: margin-left 0.3s ease;
    line-height: 1.5rem;
    @include tablet-viewport {
      margin: 2rem 1rem 2rem 7rem;
      width: 70%;
      font-size: 2rem;
      line-height: 3rem;
    }
    @include desktop-viewport {
      text-align: left;
      margin: 2rem;
      width: 65%;
    }
  }

  &__button {
    display: block;
    // width: 50%;
    text-align: center;
    padding: 1rem;
    margin: 1rem;
    background-color: #b91313;
    color: #fff;
    text-transform: capitalize;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    z-index: 3; 
    position: relative; 
    &:hover {
      background-color: darken(#b91313, 10%);
    }
    @include tablet-viewport {
      width: 30%;
    }
    @include desktop-viewport {
      width: 20%;
    }
  }

  a {
    text-decoration: none;
    color: white;
  }
}
